import React from 'react';
import ReactDOM from 'react-dom/client'; // استخدام 'react-dom/client' بدلاً من 'react-dom'
import './index.css';
import App from './App';
import ProductDetails from './ProductDetails';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';  // استيراد التوجيهات من react-router-dom
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './i18n'; 

const root = ReactDOM.createRoot(document.getElementById('root'));  // استخدام createRoot

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/product/:productId" element={<ProductDetails />} />
      </Routes>
    </Router>
  </React.StrictMode>
);











// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import ProductDetails from './ProductDetails';
// import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// ReactDOM. render(
//   <React.StrictMode>
//     <Router>
//       <Routes>
//         <Route path="/" element={<App />} />
//         <Route path="/product/:productId" element={<ProductDetails />} />
//       </Routes>
//     </Router>
//   </React.StrictMode>,
//   document.getElementById('root')
// );







// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';
// import "slick-carousel/slick/slick.css"; 
// import "slick-carousel/slick/slick-theme.css";

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
