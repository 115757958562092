import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: {
          "appName": "Offerly",
          "home": "Home",
          "about": "About",
          "contact": "Contact",
          "back": "Back",
          "loading": "Loading...",
          "productNotFound": "Product not found",
          "offerEnds": "Offer Ends:",
          "availableCities": "Available in Cities:"
        }
      },
      ar: {
        translation: {
          "appName": "أوفرلي",
          "home": "الرئيسية",
          "about": "عن",
          "contact": "اتصل",
          "back": "رجوع",
          "loading": "جارٍ التحميل...",
          "productNotFound": "المنتج غير موجود",
          "offerEnds": "تنتهي العرض:",
          "availableCities": "متوفر في المدن:"
        }
      }
    },
    lng: "en", // اللغة الافتراضية
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // لا حاجة للهروب من القيم في React
    }
  });

export default i18n;
