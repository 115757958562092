import { Client, Databases } from 'appwrite';
import React, { useEffect, useState } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import ProductDetails from './ProductDetails';
import { useTranslation } from 'react-i18next';
import './App.css';


const App = () => {

  const [products, setProducts] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();// تعريف التنقل
  const { t, i18n } = useTranslation(); // استخدام i18n

  // إعداد Appwrite Client
  const client = new Client();
  const databases = new Databases(client);

  client
    .setEndpoint('https://cloud.appwrite.io/v1') // Endpoint Appwrite الخاص بك
    .setProject('offerly-project-id'); // معرف مشروع Appwrite الخاص بك

  // جلب المنتجات من قاعدة البيانات
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await databases.listDocuments(
          'offerly-database-id',
          'offers-collection-id'
        );
        setProducts(response.documents);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  // التنقل إلى صفحة تفاصيل المنتج
  const handleProductClick = (productId) => {

    console.log("Navigating to product ID:", productId); 
    navigate(`/product/${productId}`);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng); // تغيير اللغة
    setDrawerOpen(false); // إغلاق Drawer بعد تغيير اللغة
  };

  const ProductCard = ({ product, onClick }) => {
    return (
      <div className="product-card" onClick={onClick}>
        <div className="product-image">
          <img src={product.images[0]} alt={product.name} />
        </div>
        <div className="product-info">
          <h3 className="product-name">{product.name}</h3>
          <h4 className="product-arabic-name">{product.arabicName}</h4>
          {/* يمكنك إضافة المزيد من المعلومات إذا لزم الأمر */}
        </div>
      </div>
    );
  };

  return (
    <div>
      <AppBar position="static" style={{ backgroundColor: '#3FC1BE' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={toggleDrawer}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {t('appName')}
          </Typography>
          <Button color="inherit" onClick={() => navigate('/')}>{t('home')}</Button>
        </Toolbar>
      </AppBar>

      <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
        <List>
          <ListItem button onClick={handleDrawerClose}>
            <Typography>{t('about')}</Typography>
          </ListItem>
          <ListItem button onClick={handleDrawerClose}>
            <Typography>{t('contact')}</Typography>
          </ListItem>
          <ListItem button onClick={() => changeLanguage('en')}>
            <Typography>English</Typography>
          </ListItem>
          <ListItem button onClick={() => changeLanguage('ar')}>
            <Typography>العربية</Typography>
          </ListItem>
        </List>
      </Drawer>

      <div className="products-grid">
      {products.map((product) => (
        <ProductCard 
          key={product.offerId} 
          product={product} 
          onClick={() => handleProductClick(product.offerId)} 
        />
      ))}
    </div>

      {/* <div className="products-grid">
        {products.map((product) => (
          <div 
            key={product.$id} 
            className="product-card"
            onClick={() => handleProductClick(product.$id)} // عند الضغط على البطاقة يأخذني إلى صفحة المنتج
          >
            <img src={product.images[0] || 'https://via.placeholder.com/300'} alt={product.name} />
            <h2>{product.name} ({product.arabicName})</h2>
            <p className="category">Category: {product.categoryName}</p>
            <p className="cities">Cities: {product.cities.join(', ')}</p>
          </div>
        ))}
      </div> */}

      <Footer />
    </div>
  );
};

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#3FC1BE', color: 'white', textAlign: 'center', padding: '10px 0' }}>
      <Typography variant="body1">© 2024 Offerly. All rights reserved.</Typography>
    </footer>
  );
};

export default App;
