import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Client, Databases } from 'appwrite'; 
import Slider from 'react-slick';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ProductDetails = () => {
  const { productId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // استخدام i18n
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const client = new Client();
    const databases = new Databases(client);

    client
    .setEndpoint('https://cloud.appwrite.io/v1') // Endpoint Appwrite الخاص بك
    .setProject('offerly-project-id'); // معرف مشروع Appwrite الخاص بك


    const fetchProductDetails = async () => {
      try {
        const response = await databases.getDocument(
            'offerly-database-id', // databaseId
            'offers-collection-id', // collectionId
            productId, // documentId
            [] // queries (optional)
        );
        console.log("Product Data:", response);
        setProduct(response);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching product details:", error);
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [productId]);

  if (loading) return <div>{t('loading')}</div>;
  if (!product) return <div>{t('productNotFound')}</div>;

  if (!Array.isArray(product.images)) {
    return <div>Product images not found</div>;
  }

  return (
    <div className="product-details-container">
      <Button variant="contained" color="primary" onClick={() => navigate(-1)}>{t('back')}</Button>
      <h1>{product.name} ({product.arabicName})</h1>

      <Slider>
        {product.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Product Image ${index + 1}`} className="slider-image" />
          </div>
        ))}
      </Slider>

      <div className="product-info">
        <p>{t('offerEnds')} {product.endDate}</p>
        <p>{t('availableCities')} {product.cities.join(', ')}</p>
      </div>
    </div>
  );
};

export default ProductDetails;
